// other
import React from 'react';
import styled from 'styled-components';
import {
  CROCCHA_API,
  CrocchaLogo,
  AppleStore,
  GooglePlay,
  Mockup,
  ServiceBg,
  BgProductSp,
  BgProduct,
  BgImg01,
  BgImg02,
  Img01,
  Img02,
  Img03,
  Img04,
  Img05,
  LogoGroup,
  LogoGroupSP,
  MetaDesc,
  MetaOGP,
} from '../utils';
import BaseLayoutTemplate from '../components/environments/BaseLayoutTemplate';
import HeadBase from '../components/molecules/HeadBase';
import Anchor from '../components/atoms/Anchor';
import LazyLoadImage from '../components/atoms/LazyLoadImage';
import { breakpoint, mainColor } from '../components/theme';
import OtherService from '../components/molecules/OtherService';
import TitleImg from '../../static/images/croccha_ttl.svg';
import Point01 from '../../static/images/point1.svg';
import Point02 from '../../static/images/point2.svg';
import ContactServiceForm from '../components/organisms/ContactSearviceForm';
import ShopIcon from '../../static/images/icon-shop.svg';

const PhilosophyPage = () => {
  return (
    <BaseLayoutTemplate>
      <HeadBase
        metas={{
          title: 'croccha | tryangle株式会社',
          description: MetaDesc,
          openGraph: {
            type: 'article',
            url: `${CROCCHA_API.SELF_DOMAIN}/philosophy/`,
            title: 'croccha | tryangle株式会社',
            description: MetaDesc,
            images: [
              {
                url: MetaOGP,
                width: 1200,
                height: 630,
                alt: 'tryangle株式会社',
              },
            ],
            site_name: 'tryangle株式会社',
          },
        }}
      />

      <Wrap>
        {/* タイトル */}
        <Title>
          『croccha』
          <br />
          ハンドメイドユーザーのための
          <br />
          教えあえるSNS
        </Title>
        <MainText>
          「ハンドメイドを教え合う」をコンセプトに
          <br />
          ITの力で、ハンドメイドをもっと身近にして、
          <br />
          誰でも作りたいを実現できる
          <br />
          作家と作家、作家と企業が繋がる世界を<span>目指しています。</span>
          ハンドメイドをもっと多くの方が便利に楽しむ
          <br />
          そんな世界を作りたいを考えています。
        </MainText>

        <Croccha>
          <CrocchaTitle>
            <LazyLoadImage
              src={TitleImg}
              alt='顧客と作るハンドメイドコミュニティcroccha'
            />
          </CrocchaTitle>
          <Point>
            <LazyLoadImage
              src={Img01}
              alt='公認ショップ紹介スクリーンショット'
            />
            <CrocchaBalloon>
              <h3>公認ショップ</h3>
              <p>
                材料を製造販売する企業様（小売店・メーカー・卸）に公認ショップとして企業アカウントをご用意しております。
                <br />
                御社の商品・サービスのPR /
                ブランディングにcrocchaをご利用頂けます。
              </p>
              <span>
                ※公認ショップは月額利用料がかかります。（無料期間あり）
                <br />
                詳しくは弊社営業担当までお問い合わせください。
              </span>
            </CrocchaBalloon>
            <BgImg src={BgImg01} alt='ハンドメイドアイコン' />
          </Point>
          <Point2>
            <BgImg src={BgImg02} alt='ハンドメイドアイコン' />
            <CrocchaBalloon2>
              <h3>ファンれぽ</h3>
              <p>
                ユーザーが作品の投稿に企業をタグ付けし、材料を購入したブランドや好きなお店を応援します！
                <br />
                企業にとってユーザーの投稿にいいねやコメントを送るなどコミュニケーションを取りやすくした仕組みです。
              </p>
            </CrocchaBalloon2>
            <LazyLoadImage src={Img02} alt='ファンれぽ紹介スクリーンショット' />
          </Point2>
          <section>
            <h3>
              <span>アプリ</span>でできること
            </h3>
            <ul>
              <li>
                <LazyLoadImage src={Img03} alt='ユーザーがアプリでできること' />
              </li>
              <li>
                <LazyLoadImage src={Img04} alt='crocchaの機能' />
              </li>
              <li>
                <LazyLoadImage
                  src={Img05}
                  alt='公認ショップがアプリでできること'
                />
              </li>
            </ul>
          </section>
          <section>
            <h3>
              <span>croccha</span>導入企業一覧
            </h3>
            <LogoWrap>
              <LazyLoadImage src={LogoGroup} alt='croccha導入企業一覧' />
            </LogoWrap>
            <LogoWrapSP>
              <LazyLoadImage src={LogoGroupSP} alt='croccha導入企業一覧' />
            </LogoWrapSP>
          </section>
          <Recruit>
            <img src={ShopIcon} alt='公認ショップ募集アイコン' />
            <h3>
              <span>crocchaを一緒に盛り上げてくださる</span>
              公認ショップを募集しています
            </h3>
            <p>
              ハンドメイドSNS「croccha」の公認ショップになりませんか？
              <br />
              公認ショップになるとこんなことができます。
            </p>
            <ul>
              <li>
                ・crocchaの投稿に自社ECの商品をタグ付けることができ、
                購買を促進できます。
              </li>
              <li>
                ・投稿を通じてファンを増やすことができるコミュニティ機能があります。
              </li>
              <li>・お店のクーポン・セール情報を掲載することができます。</li>
            </ul>
            <p>
              詳細に関しては、
              <a href='https://web.croccha.com/applyshop' target='_blank'>
                https://web.croccha.com/applyshop
              </a>
              <br />
              もしくは営業担当(
              <a href='mailto:sales@croccha.jp'>sales&#64;croccha.jp</a>
              )までお問い合わせください。
            </p>
          </Recruit>
          <Recruit>
            <LazyLoadImage
              src='https://static.croccha.com/webapp/authorize/img02.jpg'
              alt='公認クリエイター募集'
            />
            <h3>
              <span>crocchaを一緒に盛り上げてくださる</span>
              公認クリエイターを募集しています
            </h3>
            <p>
              crocchaでは、作りたいを応援してくださる公認クリエイターさまを定期的に募集しています。
              <br />
              公認クリエイター募集のページはこちらです
              <br />
              <a
                href='https://web.croccha.com/events/recruit/authorizeusers'
                target='_blank'
              >
                https://web.croccha.com/events/recruit/authorizeusers
              </a>
            </p>
            <p>
              公認クリエイターに関するご質問は、サービス担当(
              <a href='mailto:info@croccha.jp'>info&#64;croccha.jp</a>)
              <br />
              までお気軽にお問い合わせください。
            </p>
          </Recruit>
        </Croccha>

        {/* アプリ案内SP */}
        <BannerSpWrap>
          <CrocchaLogoWrap>
            <LazyLoadImage src={CrocchaLogo} alt='crocchaロゴ' />
          </CrocchaLogoWrap>

          <FooterMessage>
            <div>ハンドメイド好きが</div>
            <div>集まるアプリ</div>
          </FooterMessage>

          <MessageWrap>
            <span>"ハンドメイドを教え合う"をテーマに</span>
            <span>材料とレシピがわかって</span>
            <span>そのままお店の材料を</span>
            <span>直接購入できるアプリです</span>
          </MessageWrap>
          <ButtonAreaSp>
            <ButtonList>
              <WebappLink url='https://web.croccha.com/'>Web版App</WebappLink>
              <StoreLink url='https://itunes.apple.com/jp/app/croccha/id1420803859?ls=1&mt=8'>
                <LazyLoadImage
                  src={AppleStore}
                  alt='App Storeからダウンロード'
                />
              </StoreLink>
              <StoreLink url='https://play.google.com/store/apps/details?id=com.croccha.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <LazyLoadImage
                  src={GooglePlay}
                  alt='Google Playで手に入れよう'
                />
              </StoreLink>
            </ButtonList>
            <MockImg src={Mockup} alt='ハンドメイド好きが集まるアプリ' />
          </ButtonAreaSp>
        </BannerSpWrap>

        {/* アプリ案内PC */}
        <BannerPcWrap>
          <BannerContainer>
            <MockImg src={Mockup} alt='ハンドメイド好きが集まるアプリ' />
            <BannerRightSide>
              <LogoContainer>
                <CrocchaLogoWrap>
                  <LazyLoadImage src={CrocchaLogo} alt='crocchaロゴ' />
                </CrocchaLogoWrap>

                <FooterMessage>
                  <div>ハンドメイド好きが</div>
                  <div>集まるアプリ</div>
                </FooterMessage>
              </LogoContainer>
              <MessageWrap>
                <span>"ハンドメイドを教え合う"をテーマに</span>
                <span>材料とレシピがわかって</span>
                <span>そのままお店の材料を</span>
                <span>直接購入できるアプリです</span>
              </MessageWrap>
              <ButtonList>
                <WebappLink url='https://web.croccha.com/'>Web版App</WebappLink>
                <StoreLink url='https://itunes.apple.com/jp/app/croccha/id1420803859?ls=1&mt=8'>
                  <LazyLoadImage
                    src={AppleStore}
                    alt='App Storeからダウンロード'
                  />
                </StoreLink>
                <StoreLink url='https://play.google.com/store/apps/details?id=com.croccha.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                  <LazyLoadImage
                    src={GooglePlay}
                    alt='Google Playで手に入れよう'
                  />
                </StoreLink>
              </ButtonList>
            </BannerRightSide>
          </BannerContainer>
        </BannerPcWrap>

        <Section>
          <h2>お問い合わせ</h2>
          <ContactServiceForm service='croccha' />
        </Section>

        {/* その他のサービス */}
        <OtherService page='croccha' />
      </Wrap>
    </BaseLayoutTemplate>
  );
};

export default PhilosophyPage;

const Wrap = styled.div`
  width: 100%;
  max-width: 884px;
  margin: 0 auto;

  @media ${breakpoint.tabOnly} {
    width: 100vw;
    max-width: auto;
  }

  @media ${breakpoint.sp} {
    width: 100vw;
    max-width: auto;
  }
`;

const Title = styled.h1`
  margin: 40px auto 32px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;

  @media ${breakpoint.sp} {
    font-size: 24px;
  }
`;

const MainText = styled.p`
  margin-bottom: 80px;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  span {
    ::after {
      content: '';
      display: block;
    }
  }

  @media ${breakpoint.sp} {
    margin-bottom: 40px;
    font-size: 14px;
    span {
      display: block;
    }
  }
`;

const Croccha = styled.section`
  padding-bottom: 60px;
  background-color: rgba(243, 167, 165, 0.26);

  // アプリでできること
  // 導入企業一覧
  section {
    margin: 0 32px 24px;
    padding: 32px 24px;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;

    @media ${breakpoint.sp} {
      margin: 0 16px 24px;
      padding: 32px 16px;
    }

    h3 {
      margin-bottom: 24px;
      font-size: 22px;

      span {
        color: ${mainColor};
      }
    }

    ul {
      display: flex;
      li:nth-child(2) {
        margin: 0 24px;
      }

      @media ${breakpoint.sp} {
        flex-direction: column;
        li:nth-child(2) {
          margin: 24px 0;
        }
      }
    }
  }
`;

const LogoWrap = styled.div`
  @media ${breakpoint.sp} {
    display: none;
  }
`;

const LogoWrapSP = styled.div`
  @media ${breakpoint.tab} {
    display: none;
  }
`;

const CrocchaTitle = styled.h2`
  margin-bottom: 40px;
  padding: 32px 0 24px;
  background-color: #f3a7a5;
  text-align: center;

  img {
    width: 440px;

    @media ${breakpoint.sp} {
      width: 80%;
    }
  }
`;

const Point = styled.div`
  display: grid;
  grid-template-columns: 156px auto 156px;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  padding: 0 24px;
  div:first-child {
    text-align: center;
  }

  @media ${breakpoint.tabOnly} {
    grid-template-columns: 18% auto 18%;
  }

  @media ${breakpoint.sp} {
    display: flex;
    flex-direction: column;

    img {
      width: 60%;
    }
  }
`;

const Point2 = styled(Point)`
  @media ${breakpoint.sp} {
    flex-direction: column-reverse;
  }

  div:last-child {
    text-align: center;
  }
`;

const BgImg = styled.img`
  @media ${breakpoint.sp} {
    display: none;
  }
`;

const CrocchaBalloon = styled.div`
  position: relative;
  margin: 0 32px;
  padding: 24px;
  background-color: #fff;
  border-radius: 10px;

  ::after {
    content: '';
    position: absolute;
    top: 20%;
    left: -20px;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #fff;
    border-left: 10px solid transparent;
    transform: rotate(-90deg);
  }

  @media ${breakpoint.sp} {
    margin: 32px 0 0;

    ::after {
      top: -20px;
      left: calc(50% - 10px);
      transform: rotate(0deg);
    }
  }

  h3 {
    position: relative;
    margin-bottom: 16px;
    padding: 10px 0 16px 40px;
    font-size: 22px;
    border-bottom: 1px solid ${mainColor};

    ::before {
      content: '';
      background-image: url(${Point01});
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
    }
  }

  p {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 1.8;
  }

  span {
    font-size: 12px;
  }
`;

const CrocchaBalloon2 = styled(CrocchaBalloon)`
  ::after {
    left: auto;
    right: -20px;
    transform: rotate(90deg);
  }

  h3 {
    ::before {
      background-image: url(${Point02});
    }
  }

  @media ${breakpoint.sp} {
    ::after {
      top: -20px;
      left: calc(50% - 10px);
      right: auto;
      transform: rotate(0deg);
    }
  }
`;

// フッタ画像（SP）
const BannerSpWrap = styled.div`
  padding-top: 45px;
  text-align: center;
  background-image: url(${ServiceBg});
  background-repeat: repeat;
  @media ${breakpoint.tab} {
    display: none;
  }
`;

const ButtonAreaSp = styled.div`
  padding-top: 2rem;
  background-image: url(${BgProductSp});
  background-repeat: repeat;
  background-size: contain;
`;

/* フッタ画像（PC） */
const BannerPcWrap = styled.div`
  background-image: url(${BgProduct}), url(${ServiceBg});
  background-repeat: repeat;
  background-size: cover;
  background-position: bottom;
  @media ${breakpoint.sp} {
    display: none;
  }
`;

const BannerContainer = styled.div`
  margin: 0 auto;
  padding: 3rem 1rem 0;
  max-width: 800px;
  display: grid;
  grid-template-columns: 340px 1fr;
  grid-gap: 50px;
`;

// フッタSP/PC共通定義
const MockImg = styled(LazyLoadImage)`
  @media ${breakpoint.sp} {
    width: 80%;
  }
  @media ${breakpoint.tab} {
    grid-column: 1;
    padding-top: 1.5rem;
  }
`;

const CrocchaLogoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  padding: 6px;
  border: 1px solid #fff;
  border-radius: 15px;

  @media ${breakpoint.sp} {
    margin: auto;
  }
`;

const BannerRightSide = styled.div`
  grid-column: 2;
  text-align: left;
  color: #fff;
`;

const LogoContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
`;

const FooterMessage = styled.div`
  font-weight: bold;
  color: #fff;
  @media ${breakpoint.sp} {
    margin: 20px 0;
    font-size: 20px;
  }
  @media ${breakpoint.tab} {
    margin-left: 24px;
    font-size: 24px;
  }
`;

const MessageWrap = styled.p`
  @media ${breakpoint.sp} {
    padding: 0 46px;
  }
  color: white;
  font-weight: bold;
  > span {
    /* 改行位置を調整 */
    display: inline-block;
  }
`;

const ButtonList = styled.div`
  @media ${breakpoint.sp} {
    margin: 3rem auto 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media ${breakpoint.tab} {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
`;

const WebappLink = styled(Anchor)`
  text-align: center;
  line-height: 45px;
  color: #fff;
  background: ${mainColor};
  border-radius: 10px;
  font-weight: bold;
  @media ${breakpoint.sp} {
    width: 150px;
    margin-bottom: 1.5rem;
  }
`;

const StoreLink = styled(Anchor)`
  @media ${breakpoint.sp} {
    width: 150px;
    margin-bottom: 1.5rem;
  }
`;

const Section = styled.section`
  margin-top: 60px;

  h2 {
    margin-bottom: 32px;
    font-size: 24px;
    text-align: center;
  }
`;

// 公認ショップ＆ユーザー募集
const Recruit = styled.section`
  margin: 0 32px 24px;
  padding: 32px 24px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;

  @media ${breakpoint.sp} {
    margin: 0 16px 24px;
    padding: 32px 16px;
  }

  img {
    width: 90px;
    margin-bottom: 24px;
  }

  h3 {
    margin-bottom: 24px;
    font-size: 22px;

    span {
      display: block;
      color: #000 !important;
    }
  }

  p {
    margin-bottom: 16px;

    a {
      color: ${mainColor};
    }
  }

  ul {
    flex-direction: column;
    margin: 32px auto;

    li:nth-child(2) {
      margin: 8px 0 !important;
    }
  }

  @media ${breakpoint.sp} {
    margin: 0 16px 24px;
    padding: 32px 16px;

    img {
      width: 60px;
    }

    h3 {
      font-size: 18px !important;
    }

    p,
    ul {
      font-size: 14px;
      text-align: left;
    }

    ul {
      margin: 16px auto;
    }
  }
`;
