// compontns
import LazyLoadImage from '../../atoms/LazyLoadImage';
import Anchor from '../../atoms/Anchor';
import { breakpoint } from '../../theme';

// other
import React from 'react';
import styled from 'styled-components';
import { DXSupportMainImg, CrocchaImg, SDGsTop, ServiceCrocchaShop, ShopURL } from '../../../utils';

const OtherService = ({ page }: { page: string }) => {
  return (
    <Section>
      <h2>その他のサービス</h2>
      <ul>
        {page === 'it-support' && (
          <>
            <li>
              <Anchor url='croccha'>
                <OtherServiceTitle>croccha</OtherServiceTitle>
                <OtherServiceDesc>
                  ハンドメイドユーザーのための教え合えるSNS
                </OtherServiceDesc>
                <ServiceImage src={CrocchaImg} />
              </Anchor>
            </li>
            <li>
              <Anchor url={ShopURL}>
                <OtherServiceTitle>croccha shop</OtherServiceTitle>
                <OtherServiceDesc>
                UV/LEDレジン液とハンドメイド材料のお店
                </OtherServiceDesc>
                <ServiceImage src={ServiceCrocchaShop} />
              </Anchor>
            </li>
            <li>
              <Anchor url='sdgs-handmade'>
                <OtherServiceTitle>SDGs × ハンドメイド</OtherServiceTitle>
                <OtherServiceDesc>
                  クリエイターのチカラでアップサイクル商品へ
                </OtherServiceDesc>
                <ServiceImage src={SDGsTop} />
              </Anchor>
            </li>
          </>
        )}
        {page === 'croccha' && (
          <>
            <li>
              <Anchor url={ShopURL}>
                <OtherServiceTitle>croccha shop</OtherServiceTitle>
                <OtherServiceDesc>
                UV/LEDレジン液とハンドメイド材料のお店
                </OtherServiceDesc>
                <ServiceImage src={ServiceCrocchaShop} />
              </Anchor>
            </li>
            <li>
              <Anchor url='sdgs-handmade'>
                <OtherServiceTitle>SDGs × ハンドメイド</OtherServiceTitle>
                <OtherServiceDesc>
                  クリエイターのチカラでアップサイクル商品へ
                </OtherServiceDesc>
                <ServiceImage src={SDGsTop} />
              </Anchor>
            </li>
            <li>
              <Anchor url='it-support'>
                <OtherServiceTitle>IT・DX支援</OtherServiceTitle>
                <OtherServiceDesc>
                  システム開発
                  <br />
                  コンサルティングなど
                </OtherServiceDesc>
                <ServiceImage src={DXSupportMainImg} />
              </Anchor>
            </li>
          </>
        )}
        {page === 'sdgs-handmade' && (
          <>
            <li>
              <Anchor url='croccha'>
                <OtherServiceTitle>croccha</OtherServiceTitle>
                <OtherServiceDesc>
                  ハンドメイドユーザーのための教え合えるSNS
                </OtherServiceDesc>
                <ServiceImage src={CrocchaImg} />
              </Anchor>
            </li>
            <li>
              <Anchor url={ShopURL}>
                <OtherServiceTitle>croccha shop</OtherServiceTitle>
                <OtherServiceDesc>
                UV/LEDレジン液とハンドメイド材料のお店
                </OtherServiceDesc>
                <ServiceImage src={ServiceCrocchaShop} />
              </Anchor>
            </li>
            <li>
              <Anchor url='it-support'>
                <OtherServiceTitle>IT・DX支援</OtherServiceTitle>
                <OtherServiceDesc>
                  システム開発
                  <br />
                  コンサルティングなど
                </OtherServiceDesc>
                <ServiceImage src={DXSupportMainImg} />
              </Anchor>
            </li>
          </>
        )}
      </ul>
    </Section>
  );
};

export default OtherService;

const Section = styled.section`
  text-align: center;

  max-width: 800px;
  margin: 0 auto;
  padding: 60px 0 100px;
  border-top: 1px solid #ddd;

  @media ${breakpoint.tabOnly} {
    max-width: none;
    width: 100vw;
  }

  h2 {
    margin-bottom: 32px;
    font-size: 24px;
    text-align: center;
  }
  h3 {
    font-size: 26px;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      width: calc(800px / 3 - 30px);
      margin: 0 15px;
      padding: 24px 16px 16px;
      border: 1px solid #ddd;
      border-radius: 4px;
      text-align: center;

      @media ${breakpoint.tabOnly} {
        width: calc(100vw / 3 - 30px);
      }
    }

    @media ${breakpoint.sp} {
      flex-direction: column;
      li {
        width: calc(100vw - 32px);
        margin: 0 16px;

        :nth-child(2) {
          margin: 24px 16px;
        }
      }
    }
  }
`;

const OtherServiceTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

const OtherServiceDesc = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  margin: 8px auto 12px;
  font-size: 14px;
`;

const ServiceImage = styled(LazyLoadImage)`
  width: calc(800px / 3 - 30px - 32px);
  height: calc((800px / 3 - 30px - 32px) * 0.562);
  object-fit: cover;

  @media ${breakpoint.tabOnly} {
    width: calc(100vw / 3 - 30px - 32px);
    height: calc((100vw / 3 - 30px - 32px) * 0.562);
  }

  @media ${breakpoint.sp} {
    width: calc(100vw - 32px - 32px);
    height: calc((100vw - 32px - 32px) * 0.562);
  }
`;
